import { TariffNatureRepoImpl } from "domain/repository/TariffNature/TariffNatureRepoImpl";
import { useTariffNatureMaintenanceTracked } from "presentation/store/TariffNature/TariffNatureMaintenanceProvider";
import { TariffNatureMaintenanceVM } from "presentation/viewModel/TariffNature/TariffNatureMaintenanceVM";
import { useMemo } from "react";

export const useTariffNatureMaintenanceVM = () => {
    const [, setTariffNatureMaintenanceState] = useTariffNatureMaintenanceTracked();
    const tariffNatureMainVM = useMemo(() =>
        TariffNatureMaintenanceVM({
            dispatch: [setTariffNatureMaintenanceState],
            repo: TariffNatureRepoImpl(),
        }), [setTariffNatureMaintenanceState])

    return tariffNatureMainVM
}


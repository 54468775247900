import { TariffNatureMaintenanceConstant } from "presentation/constant/TariffNature/TariffNatureMaintenanceConstant";
import { useTariffNatureMaintenanceVM } from "presentation/hook/TariffNature/useTariffNatureMaintenanceVM";
import { useMessageBarVM } from "presentation/hook/useMessageBar";
import { useTariffNatureMaintenanceTracked } from "presentation/store/TariffNature/TariffNatureMaintenanceProvider";
import { memo, useCallback, useState } from "react";
import { HPHBreadcrumb, IconButton, Loader } from "veronica-ui-component/dist/component/core";
import { Breadcrumb, Sidebarheader, StyledAction } from "veronica-ui-component/dist/component/core/styled/uiFramework.styled";

const TariffNatureEditPanelTitleBar: React.FC = () => {
    const [tariffNatureState] = useTariffNatureMaintenanceTracked();
    const tariffNatureVM = useTariffNatureMaintenanceVM();
    const messageBarVM = useMessageBarVM();
    const { currentSelectedRow, masterState } = tariffNatureState;
    const { editingEntity, isAdd, isEditable, isRead } = masterState;

    const [isLoading, setIsLoading] = useState(false);
    const currentEntity = isRead ? currentSelectedRow : editingEntity;

    const handleReset = useCallback(async () => {
        tariffNatureVM.onReset();
    }, [tariffNatureVM]);

    const handleEdit = useCallback(() => {
        tariffNatureVM.onEditClick(currentEntity);
    }, [currentEntity, tariffNatureVM])

    const handleSave = useCallback(async () => {
        setIsLoading(true);
        try {
            tariffNatureVM.onSaveClicked();
            const res = await tariffNatureVM.onSave(currentEntity, isAdd);
            if (!!!res || !res.success) {
                messageBarVM.showError(res?.data ?? 'Save data failed.');
            } else {
                tariffNatureVM.onClose();
                setIsLoading(true);
            }
        } catch (error) {

        } finally {
            setIsLoading(false)
        }

    }, [currentEntity, isAdd, messageBarVM, tariffNatureVM]);

    return <Sidebarheader style={{ width: '100%' }}>
        {isLoading && <Loader Indicator="Spinner" size="Medium" />}
        <Breadcrumb>
            <HPHBreadcrumb breadcrumbData={[{ title: TariffNatureMaintenanceConstant.CATEGORY },
            { title: TariffNatureMaintenanceConstant.TITLE }]} onCurrentClick={() => { }}>
            </HPHBreadcrumb>
        </Breadcrumb>
        <StyledAction>
            {!(isAdd || isEditable) && <IconButton fileName='Icon-pen' size='medium' toolTipText={'Edit'} toolTipArrow={false} onClick={handleEdit} />}
            {(isAdd || isEditable) && <IconButton fileName="Icon-reset" size="medium" toolTipArrow={false} toolTipPlacement="bottom" toolTipText={'Reset'} onClick={handleReset} />}
            {<IconButton fileName="Icon-cross" size="medium" disabled={false} toolTipArrow={false} toolTipPlacement="left" toolTipText={'Close'} onClick={tariffNatureVM.onClose} />}
            {(isAdd || isEditable) && <IconButton fileName="Icon-tick" size="medium" disabled={false} toolTipArrow={false} toolTipPlacement="bottom" toolTipText={'Save'} onClick={() => handleSave()} />}
        </StyledAction>
    </Sidebarheader>
}

export default memo(TariffNatureEditPanelTitleBar);
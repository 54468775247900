export interface TariffNatureEntity {
    id: number,
    tariffNature: string,
    tariffNatureDesc: string | null,
    activeInd: "Y" | "N",

    [key: string]: string | boolean | number | null | undefined | Object
}

export const EMPTY_TARIFF_NATURE_ENTITY: TariffNatureEntity = {
    id: 0,
    tariffNature: "",
    tariffNatureDesc: "",
    activeInd: "Y"
}
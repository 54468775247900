export const tariffNatureMaintenanceRequiredFieldList: string[] = [
    'tariffNature',
];

export const TariffNatureMaintenanceConstant = {
    CATEGORY: "Tariff",
    TITLE: "Tariff Nature",
    TARIFF_NATURE: "Tariff Nature",
    TAR_NATURE_DESC: 'Tariff Nature Desc',
    ACTIVE_IND: 'Active Ind.',
}

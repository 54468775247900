
import { TariffNatureEntity, EMPTY_TARIFF_NATURE_ENTITY } from "domain/entity/TariffNature/TariffNatureEntity";
import { BaseViewChangeSate } from "../BaseViewChangeState";
import { DropdownProps } from "../DropdownProps";

export interface TariffNatureDropdownOptions {
    tariffNatureTypeDropdownOptions: DropdownProps[],
}
export interface MasterChangeState extends BaseViewChangeSate {
    editingEntity: TariffNatureEntity,
}

export interface TariffNatureMaintenanceModel {
    isLoading: boolean,
    tableData: TariffNatureEntity[],
    currentSelectedRow: TariffNatureEntity,
    selectedRows: TariffNatureEntity[],
    dynamicOptions: TariffNatureDropdownOptions,
    masterState: MasterChangeState,
    isBackMaster: boolean,
    isShowEditPanel: boolean,
}

export const EMPTY_MASTER_TARIFF_NATUREANY_MODEL: TariffNatureMaintenanceModel = {
    isLoading: false,
    tableData: [],
    currentSelectedRow: { ...EMPTY_TARIFF_NATURE_ENTITY },
    selectedRows: [],
    dynamicOptions: {
        tariffNatureTypeDropdownOptions: []
    },
    masterState: {
        isSliderOpen: false,
        isTabularDataActive: false,
        isAdd: false,
        isRead: false,
        isEditable: false,
        isSaveClicked: false,
        lastEditRowId: "",
        isSelected: false,
        slider: {
            isAdd: false,
            isRead: false,
            isEditable: false
        },
        editingEntity: EMPTY_TARIFF_NATURE_ENTITY,
    },
    isBackMaster: false,
    isShowEditPanel: false,
}
import { EMPTY_MASTER_TARIFF_NATUREANY_MODEL } from "presentation/model/TariffNature/TariffNatureMaintenanceModel";
import { useState } from "react";
import { createContainer } from "react-tracked";

const {
    Provider: TariffNatureMaintenanceProvider,
    useTracked: useTariffNatureMaintenanceTracked
} = createContainer(() => useState(EMPTY_MASTER_TARIFF_NATUREANY_MODEL), {concurrentMode: true});
export { TariffNatureMaintenanceProvider, useTariffNatureMaintenanceTracked };


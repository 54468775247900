import * as yup from 'yup';


export const commonTariffNatureMaintenanceValidation: { [x: string]: any; } = {
  tariffNature: yup.string().required("Missing"),
};

export const createTariffNatureMaintenanceValidation: { [x: string]: any; } = {
  ...commonTariffNatureMaintenanceValidation,
};

export const createTariffNatureMaintenanceValidationSchema = yup.object().shape(createTariffNatureMaintenanceValidation);


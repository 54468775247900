import { TariffNatureMaintenanceConstant } from "presentation/constant/TariffNature/TariffNatureMaintenanceConstant";
import { useTariffNatureMaintenanceVM } from "presentation/hook/TariffNature/useTariffNatureMaintenanceVM";
import { useTariffNatureMaintenanceTracked } from "presentation/store/TariffNature/TariffNatureMaintenanceProvider";
import { CriteriaItemContainer } from "presentation/view/components/CriteriaItemContainer";
import { NbisCommonField } from "presentation/view/components/NbisCommonField";
import { useMemo } from "react";
import { FieldType, HPHCheckbox, IFieldValue } from "veronica-ui-component/dist/component/core";
import { SidebarCaption, SidebarTitle, StyledSidebar } from "veronica-ui-component/dist/component/core/styled/uiFramework.styled";
import TariffNatureEditPanelTitleBar from "./TariffNatureEditPanelTitleBar";

export const TariffNatureMaintenanceFormPanel = () => {
    const [tariffNatureState] = useTariffNatureMaintenanceTracked();
    const tariffNatureVM = useTariffNatureMaintenanceVM();
    const {currentSelectedRow, masterState} = tariffNatureState;
    const {editingEntity, isAdd, isEditable, isRead, isSaveClicked, allFormState} = masterState;
    const SCREEN_CONSTANT = TariffNatureMaintenanceConstant;
    

    const currentEntity = isRead? currentSelectedRow : editingEntity;

    const memoTariffNature = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "330px", marginBottom: "24px" }}>

            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={!isAdd}
                isShowOptional={false}
                readOnlyValue={currentEntity?.tariffNature || ''}
                fieldValue={currentEntity?.tariffNature}
                fieldLabel={SCREEN_CONSTANT.TARIFF_NATURE}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'tariffNature'}
                isShowMissingError={true}
                maxLength={10}
                options={tariffNatureState.dynamicOptions.tariffNatureTypeDropdownOptions}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => 
                    tariffNatureVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />        
        </div>
        , [allFormState, isAdd, currentEntity?.tariffNature, SCREEN_CONSTANT.TARIFF_NATURE, isSaveClicked, tariffNatureState.dynamicOptions.tariffNatureTypeDropdownOptions, tariffNatureVM])

        const memoDesc = useMemo(() =>
            <div className='im-flex-row-item' style={{ width: "330px", marginBottom: "24px" }}>
    
                <NbisCommonField
                    errorMessages={allFormState}
                    isReadOnly={!(isEditable || isAdd)}
                    isShowOptional={true}
                    readOnlyValue={currentEntity?.tariffNatureDesc || ''}
                    fieldValue={currentEntity?.tariffNatureDesc}
                    fieldLabel={SCREEN_CONSTANT.TAR_NATURE_DESC}
                    isSaveClicked={isSaveClicked}
                    fieldType={FieldType.TEXT}
                    fieldKey={'tariffNatureDesc'}
                    isShowMissingError={true}
                    maxLength={35}
                    requiredFieldList={[]}
                    onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => 
                        tariffNatureVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
     
            </div>
            , [allFormState, isEditable, isAdd, currentEntity?.tariffNatureDesc, SCREEN_CONSTANT.TAR_NATURE_DESC, isSaveClicked, tariffNatureVM])

           

        const memoActiveInd = useMemo(() =>
            <>
                <div className='im-flex-row-item' style={{ width: "150px", marginBottom: "24px" }}>
    
                <NbisCommonField
                    fieldKey={"activeInd"}
                    requiredFieldList={[]}
                    fieldLabel={SCREEN_CONSTANT.ACTIVE_IND} 
                    fieldType={FieldType.TEXT} 
                    isReadOnly={true}
                    onFieldChange={()=>{}}/>
                <HPHCheckbox
                    label={""}
                    checked={currentEntity?.activeInd==='Y'}
                    disabled={!(isEditable || isAdd)}
                    onChange={(e) => tariffNatureVM.onCheckboxChange(e.checked, "activeInd")}
                />
            </div>
            </>
        , [SCREEN_CONSTANT.ACTIVE_IND, currentEntity?.activeInd, isAdd, isEditable, tariffNatureVM])

        
    const memoEditPanelTitleBar = useMemo(() => {
        return <TariffNatureEditPanelTitleBar/>
    }, [])


    return <StyledSidebar style={{ width: '100%', height: "100%", border: 'none', backgroundColor: '#FFFFFF', overflow: 'auto', padding: '0' }} position='right'>
        {/* Header part of add or edit record */}
        {memoEditPanelTitleBar}

        {/* Readable and Editable Form */}
        <div className={'add-edit-form'} style={{ height: 'calc(100vh - 125px)', maxHeight: 'calc(100vh - 125px)', overflow: 'auto' }}>
                <div className="flex-row-auto">
                    <div className={'flex-row-item'}>
                        <div className={'im-flex-row'}>
                            <div className={'flex-row-item-stretch'}>
                                <SidebarTitle>{!isAdd ? currentEntity.tariffNature : "NEW CODE"}</SidebarTitle>

                                {<SidebarCaption>"All fields are required unless indicated as 'optional'"</SidebarCaption>}
                            </div>
                        </div>
                        
                        <CriteriaItemContainer>
                            {memoTariffNature}
                        </CriteriaItemContainer>

                        <CriteriaItemContainer>
                            {memoDesc}
                        </CriteriaItemContainer>

                        <CriteriaItemContainer>
                            {memoActiveInd}
                        </CriteriaItemContainer>

                    </div>
                </div>
            </div>
        {/* Readable and Editable Form */}
    </StyledSidebar>
}


import { useTariffNatureMaintenanceVM } from "presentation/hook/TariffNature/useTariffNatureMaintenanceVM";
import { useTariffNatureMaintenanceTracked } from "presentation/store/TariffNature/TariffNatureMaintenanceProvider";
import { memo, useEffect, useState } from "react";
import { Loader, SliderPanel } from "veronica-ui-component/dist/component/core";
import TariffNatureTablePanel from "./TariffNatureTablePanel";

const TariffNatureMaintenance: React.FC = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [tariffNatureState] = useTariffNatureMaintenanceTracked();
    const tariffNatureVM = useTariffNatureMaintenanceVM();
    const { isBackMaster } = tariffNatureState;


    useEffect(() => {
        const initialScreen = async () => {
            setIsLoading(true);
            try {
                const results = await Promise.allSettled([
                    tariffNatureVM.onSearch(),
                    tariffNatureVM.loadDropdownOption(),
                ]);
                results.forEach((result, index) => {
                    if (index === 0 && result.status === 'fulfilled') {

                    }
                })
            } catch (error) {
                setIsLoading(false);
            }
        }
        initialScreen().then(() => {
            setIsLoading(false)
        }).catch(error => {
            setIsLoading(false)
        });

    }, [isBackMaster, tariffNatureVM])



    if (isLoading) return <Loader Indicator="Stripe" size="Large" />;

    return <>
        <div className={`main-comp-wrapper im-hide-side-form-draggable`}>
            <SliderPanel
                isOpen={false}
                draggable={false}
                leftSectionWidth={"0%"}
                rightSectionWidth={"100%"}
                leftChildren={<TariffNatureTablePanel />}
            />
        </div>
    </>
}

export default memo(TariffNatureMaintenance);
import { TariffNatureMaintenanceConstant } from "./TariffNatureMaintenanceConstant";

const SCREEN_CONSTANT = TariffNatureMaintenanceConstant;
export const INITIAL_TARIFF_NATURE_COL_DEF: any[] = [
    {
        headerName: SCREEN_CONSTANT.TARIFF_NATURE,
        field: "tariffNature",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 150,
        //pinned: "left",
        //checkboxSelection: true,
        //headerCheckboxSelection: true,

    },
    {
        headerName: SCREEN_CONSTANT.TAR_NATURE_DESC,
        field: "tariffNatureDesc",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 200,
    },
    {
        headerName: SCREEN_CONSTANT.ACTIVE_IND,
        field: "activeInd",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 130,
    },
]


import { useTariffNatureMaintenanceTracked } from "presentation/store/TariffNature/TariffNatureMaintenanceProvider";
import { SliderPanel } from "veronica-ui-component/dist/component/core";
import TariffNatureMaintenance from "./TariffNatureMaintenance";
import { TariffNatureMaintenanceFormPanel } from "./TariffNatureMaintenanceFormPanel";

export const TariffNatureMasterView: React.FC = () => {
    const [tariffNatureState] = useTariffNatureMaintenanceTracked();
    const { isShowEditPanel } = tariffNatureState;

    return <>
        <div className="main-comp-wrapper im-hide-side-form-draggable">
            <TariffNatureMaintenance />
            {
                (isShowEditPanel &&
                    <SliderPanel
                        isOpen={false}
                        leftSectionWidth={"100%"}
                        rightSectionWidth={"0%"}
                        leftChildren={<TariffNatureMaintenanceFormPanel />}
                    />
                )
            }
        </div>
    </>
}